import {colors} from "@c10h/colors";
import {Trans, useTranslation} from "ni18n";
import React, {useMemo} from "react";
import {v5Pages} from "src/components/_common/_constants";
import {ImageItemList} from "src/components/_common/ImageItemList";
import {Btn} from "src/components/v5/V5Comp";
import {V5Image} from "src/useV5ImgSrc";

import ContentArea from "../../composition/ContentArea";
import Section from "../../composition/Section";
import {Breakpoint, useTwMediaQuery} from "src/hooks/useTwMediaQuery";

export const ImageGrid: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();
  const isSm = !useTwMediaQuery(Breakpoint.SM);

  const bookingUrl = v5Pages.locations;

  const imageHeight = isSm ? 100 : 190;
  const itemList = useMemo(
    () => [
      {
        header: i18n.t("Book same day appointments"),
        content: i18n.t("Primary, urgent, and virtual care visits on YOUR schedule."),
        image: {
          src: "home/illustrated-list/01",
          height: imageHeight,
        },
      },
      {
        header: i18n.t("Chat with your health team"),
        content: i18n.t(
          "Get treated for a range of symptoms & conditions right from your smartphone.",
        ),
        image: {
          src: "home/illustrated-list/02",
          height: imageHeight,
        },
      },
      {
        header: i18n.t("Prescriptions at your doorstep"),
        content: i18n.t(
          "Get your medications sent straight to your chosen pharmacy or to your door—whatever works for you.",
        ),
        image: {
          src: "home/illustrated-list/03",
          height: imageHeight,
        },
      },
      {
        header: i18n.t("All your health in one place"),
        content: i18n.t(
          "Access your medical charts, complete records, and test results immediately.",
        ),
        image: {
          src: "home/illustrated-list/04",
          height: imageHeight,
        },
      },
    ],
    [i18n.lang, imageHeight],
  );

  const imageStyle = useMemo(() => ({right: 0, top: "-25%"}), []);

  return (
    <Section id="learn-more" className="pv40 pv16-md">
      <ContentArea className="flex-col">
        <h2 className="font-csb lh12 fs48 fs24-md lh14-md mb16 mb8-md dif">
          <Trans>
            <span>
              A friendlier healthcare experience,{" "}
              <strong className="db di-md darkerYellow">
                without{" "}
                <span className="pos-r zIndex1">
                  the membership fees.{" "}
                  <V5Image
                    alt=""
                    style={imageStyle}
                    src="home/word-circle"
                    className="op5 pos-a maxw20-md dn-md zIndex-1"
                  />
                </span>
              </strong>
            </span>
          </Trans>
        </h2>
        <Btn
          href={bookingUrl}
          bg={colors.gray800}
          className="mb24 mb8-md fs14-f db-f event-pdwXAy focus-bsDarkBlue3"
        >
          {i18n.t("Book an Appointment")}
        </Btn>
        <ImageItemList rows={2} items={itemList} />
      </ContentArea>
    </Section>
  );
};
