import {useTranslation} from "ni18n";
import React from "react";

import {SpecialtyId} from "../../constants/specialtyIds";
import {useGetSoonestInPracticeBySpecialtyId} from "../../hooks/useGetSoonestTime";
import {todayTomorrow4} from "../_common/Carbon";
import {LazyComp} from "../_common/LazyComp";

export const ReadableSlotTimeBySpecialtyId = ({
  specialtyId,
}: {
  specialtyId: SpecialtyId;
}): React.ReactElement => {
  const i18n = useTranslation();
  const maybeSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId,
  });
  const nextApptSlotTime = todayTomorrow4(i18n.lang, maybeSlot?.time);

  return <LazyComp>{nextApptSlotTime}</LazyComp>;
};
